import { LicenseEditedByEnum } from "@cpe/models/credits-license";
import * as FetchUtil from "../../fetch-util";

export enum ComplianceUserStatus {
  INCOMPLETE = "Incomplete",
  COMPLETED = "Completed"
}

export interface ComplianceUsersLicenses {
  versionId?: number;
  name: string;
  hasReportingPeriod: boolean;
  reportingDate: string;
  accountLicenseId: number;
  pendingReview?: boolean;
  lastEditedBy?: LicenseEditedByEnum;
  credits: {
    hours: number;
    remaining: number;
    required: number;
  };
  status: keyof typeof ComplianceUserStatus;
}

export interface ComplianceUserResponse {
  users: Array<{
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    tenantNames: string[];
    licenses: ComplianceUsersLicenses[];
    isClientAdmin?: boolean;
    isCurrentUser?: boolean;
    globalStatus: keyof typeof ComplianceUserStatus;
  }>;
  total: number;
}

export interface BasicUsersInfo {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  isClientAdmin?: boolean;
  isCurrentUser?: boolean;
}
export interface BasicUsersResponse {
  users: BasicUsersInfo[];
  total: number;
}

export class ComplianceUsersService {
  static count(url?: string): Promise<FetchUtil.HttpResponse<{ total: number }>> {
    return FetchUtil.get(`${complianceUsersApiMatchers.count}${url || ""}`);
  }
  static search(url?: string): Promise<FetchUtil.HttpResponse<ComplianceUserResponse>> {
    return FetchUtil.get(`${complianceUsersApiMatchers.search}${url || ""}`);
  }
  static export(search?: string): Promise<FetchUtil.HttpResponse<ComplianceUserResponse>> {
    return FetchUtil.downloadFile(`${complianceUsersApiMatchers.export}${search || ""}`);
  }
  static list(url?: string): Promise<FetchUtil.HttpResponse<BasicUsersResponse>> {
    return FetchUtil.get(`${complianceUsersApiMatchers.list}${url || ""}`);
  }
  static getUsersBulkExtProgramExtraInfo(
    id: string,
    search?: { limit: string }
  ): Promise<FetchUtil.HttpResponse<BasicUsersResponse>> {
    return FetchUtil.get(
      `${complianceUsersApiMatchers.listBulkEdit.replace(":id", id)}${
        search ? `?${new URLSearchParams(search).toString()}` : ""
      }`
    );
  }
}

export const complianceUsersApiMatchers = {
  count: "/api/v1/compliance/users/count",
  search: "/api/v1/compliance/users",
  export: "/api/v1/compliance/users/download",
  list: "/api/v1/compliance/users/basic",
  listBulkEdit: "/api/v1/certificates/external/bulk/:id/users/basic"
};
