import { Course } from "@cpe/models/course";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Courses } from "./thunks";

export const keys = {
  all: "ALL",
  inprogress: "IN_PROGRESS",
  completed: "COMPLETED",
  saved: "SAVED",
  provider: "PROVIDER",
  mycourses: "MY_COURSES"
};

export enum CoursesRouteType {
  "ALL" = "all",
  "IN_PROGRESS" = "inprogress",
  "COMPLETED" = "completed",
  "SAVED" = "saved",
  "PROVIDER" = "provider",
  "MY_COURSES" = "mycourses"
}

type SavedTabFilters = { [key in SelectedTabModel]: string };
export type SelectedTabModel = keyof typeof CoursesRouteType;
export type SelectedTabKeyModel = keyof typeof keys;
export type SelectedTabModelState = { tab: SelectedTabModel; provider?: number };

type PayloadSavedTabFiltersActionModel = {
  tab: SelectedTabModel;
  filter: string;
};

export interface CoursesState {
  savedTabFilters: SavedTabFilters;
  selectedTab?: SelectedTabModelState;
  savedItemsIds: string[];
  filteredItems: string[];
  filteredCourses: { [key: string]: Course };
  total: number | null;
  loading: boolean;
  error: string | null;
}
export const initialState: CoursesState = {
  savedTabFilters: {
    ALL: "",
    IN_PROGRESS: "",
    COMPLETED: "",
    SAVED: "",
    PROVIDER: "",
    MY_COURSES: ""
  },
  selectedTab: undefined,
  savedItemsIds: [],
  filteredItems: [],
  filteredCourses: {},
  total: null,
  loading: false,
  error: null
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState: initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<SelectedTabModelState>) => {
      state.selectedTab = action.payload;
    },
    addSavedItem: (state, action: PayloadAction<string>) => {
      state.savedItemsIds = [action.payload, ...state.savedItemsIds];
      state.filteredCourses[action.payload].state.saved = true;
    },
    removeSavedItem: (state, action: PayloadAction<string>) => {
      state.savedItemsIds = [...state.savedItemsIds.filter(productId => productId !== action.payload)];
      state.filteredCourses[action.payload].state.saved = false;
      if (state.selectedTab?.tab === "SAVED") {
        state.filteredItems = state.filteredItems.filter(productId => productId !== action.payload);
      }
    },
    setSavedTabFilters: (state, action: PayloadAction<PayloadSavedTabFiltersActionModel>) => {
      state.savedTabFilters[action.payload.tab] = action.payload.filter;
    },
    registerCourse: (state, action: PayloadAction<string>) => {
      state.filteredCourses[action.payload].actions = state.filteredCourses[action.payload].actions.filter(
        action => action !== "REGISTER"
      );
      state.filteredCourses[action.payload].actions.push("UNREGISTER");
      state.filteredCourses[action.payload].state.registered = true;
    }
  },
  extraReducers: builder => {
    builder.addCase(Courses.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.filteredItems = [];
      } else {
        const courses = (action.payload?.courses as Course[]) || [];
        state.filteredItems = [];
        courses.forEach(course => {
          state.filteredCourses[course.productId] = course;
          state.filteredItems.push(course.productId);
        });
        state.total = (action.payload?.total as number) || null;
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(Courses.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(Courses.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const {
  setSavedTabFilters,
  addSavedItem,
  removeSavedItem,
  registerCourse,
  setSelectedTab
} = coursesSlice.actions;
export default coursesSlice.reducer;
