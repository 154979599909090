import { Course, SelectedTabModel } from "@cpe/models/course";
import * as FetchUtil from "../fetch-util";

type CourseDetailResponse = {
  url: string;
};
type CourseLaunchResponse = {
  url: string;
};

export interface OptionsModel {
  value: string;
  label: string;
}
export interface FiltersModel {
  description: string;
  name: string;
  options: OptionsModel[];
  hidden: boolean;
  type: "SINGLE_SELECT" | "MULTI_SELECT" | "TOGGLE";
}
export interface TabsModel {
  description: string;
  name: SelectedTabModel;
  providerid?: number;
}

export interface CourseFiltersModel {
  filters: FiltersModel[];
  sorts: OptionsModel[];
  tabs: TabsModel[];
  preSelectedFilterOptions?: {
    [breakdownId: string]: PreSelectedFilter[];
  };
}

type PreSelectedFilter = {
  filterName: string;
  selectedValues: string[];
};

export interface CoursesResponseModel {
  courses: Course[];
  total: number;
}

export interface CounterResponseModel {
  total: number;
}

export class CoursesService {
  static save(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(`/api/v1/courses/${productId}/save`, {});
  }
  static unsave(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(`/api/v1/courses/${productId}/save`, {});
  }
  static register(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(`/api/v1/courses/${productId}/register`, {});
  }
  static unregister(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(`/api/v1/courses/${productId}/register`, {});
  }
  static detail(productId: string): Promise<FetchUtil.HttpResponse<CourseDetailResponse>> {
    return FetchUtil.get(`/course/${productId}/detail`);
  }
  static launch(productId: string): Promise<FetchUtil.HttpResponse<CourseLaunchResponse>> {
    return FetchUtil.get(`/api/v1/courses/${productId}/launch`);
  }
  static filters(): Promise<FetchUtil.HttpResponse<CourseFiltersModel>> {
    return FetchUtil.get(`/api/v1/filters/course`);
  }
  static getCourses(search?: string): Promise<FetchUtil.HttpResponse<CoursesResponseModel>> {
    return FetchUtil.get(`/api/v1/courses${search}`);
  }
  static getCounter(url?: string): Promise<FetchUtil.HttpResponse<CounterResponseModel>> {
    return FetchUtil.get(`/api/v1/courses/count${url}`);
  }
  static hide(sectionId: string, productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(`/api/v1/sections/${sectionId}/courses/${productId}/hide`, {});
  }
}
