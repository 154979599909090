import { AvailableFeaturesFlags } from "@cpe/models/feature-flag";
import { useGetFeatureFlagsQuery } from "@cpe/services/feature-flags/feature-flags.service";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { setFeatureFlags } from "../../store/active-feature-flags";
import { useActiveFeatureFlags } from "./../../hooks/index";
import { isAllowedFlag } from "./feature-flags";

export function useFeatureFlags() {
  const { activeFeatureFlags } = useActiveFeatureFlags();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>();
  const data = useGetFeatureFlagsQuery("", {
    pollingInterval: 30000
  });

  useEffect(() => {
    dispatch(setFeatureFlags(data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  useEffect(() => {
    setIsLoading(data.isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.isLoading]);

  const isFFActive = (featureFlag: AvailableFeaturesFlags | AvailableFeaturesFlags[]) => {
    const isArray = Array.isArray(featureFlag);
    const flags = isArray ? featureFlag : [featureFlag];
    return isAllowedFlag(flags as AvailableFeaturesFlags[], activeFeatureFlags);
  };
  return {
    featuresFlags: activeFeatureFlags,
    isLoading,
    isFFActive
  };
}
