import { Dropdown, Icon, Typography } from "@beckersdp/becker-ds";
import { NavigationLink } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import styled from "styled-components";
import { SessionStorageType } from "../../../../../../constants/storage";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { useComplianceSectionContext } from "../../../../../../pages/home/components/compliance-section/compliance-section-provider";
import { availableRoutes } from "../../../../../../router/routes";
import { useComplianceDisclaimerContext } from "../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { CompliancePopover } from "../compliance-popover/compliance-popover";
import { CustomDropdownMenu, CustomOption, DownChevronIcon, MenuText, NavOption } from "./styles";

type Props = {
  link: NavigationLink;
};
export const LinkWithChildren: FC<Props> = props => {
  const { link } = props;
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();
  const isComplianceLink = link.description === "Compliance" || link.url === availableRoutes.compliance;
  const complianceCredits = useComplianceSectionContext();

  const showCompliancePopover =
    isComplianceLink && sessionStorage[SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER];

  const hasLicenses = complianceCredits?.complianceCredits && complianceCredits?.complianceCredits?.length > 0;

  const render = () => {
    return (
      <CustomSpan>
        <Dropdown
          placement="bottom-start"
          data-qa-label={PageQALabels.helpDropdown}
          menu={
            <MoreMenu>
              <>
                {link.children?.map((link, index: number) => {
                  const isMyCompliance = link.url === availableRoutes.compliance;
                  return (
                    <CustomOption
                      style={{ justifyContent: "space-between", display: "flex", alignItems: "flex-end" }}
                      key={index}
                      data-qa-label={link.permission}
                      onClick={event => {
                        if (hasLicenses && isMyCompliance && shouldShowComplianceDisclaimer()) {
                          event.preventDefault();
                          openComplianceDisclaimer();
                          return;
                        }
                        window.location.href = link.url;
                      }}
                    >
                      <Typography.Text level="2" weight="bold" style={{ padding: 0 }}>
                        {link.description}
                      </Typography.Text>
                      <Icon
                        style={{
                          marginLeft: "8px",
                          visibility: window.location.pathname === link.url ? "visible" : "hidden"
                        }}
                        name="check"
                      />
                    </CustomOption>
                  );
                })}
              </>
              <></>
            </MoreMenu>
          }
        >
          <NavOption>
            <Container>
              <CustomMenuText>{link.description}</CustomMenuText>
              <DownChevronIcon style={{ marginTop: "4px" }} />
            </Container>
          </NavOption>
        </Dropdown>
      </CustomSpan>
    );
  };

  if (showCompliancePopover) {
    return <CustomCompliancePopover role="list-item">{render()}</CustomCompliancePopover>;
  }

  return render();
};

const CustomCompliancePopover = styled(CompliancePopover)`
  height: 100%;
  display: flex;
  align-items: end;
`;

const MoreMenu = styled(CustomDropdownMenu)`
  top: 0px !important;
`;

const CustomMenuText = styled(MenuText)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamily.primary};
  && {
    padding: 0 2px 0 15px;
  }
`;

const Container = styled.div`
  height: 63px;
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

const CustomSpan = styled.span`
  span {
    display: flex;
    align-items: center;
    top: 2px;
    position: relative;
    height: 100%;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 15px;
    &:hover {
      cursor: pointer;
    }
  }
  margin-bottom: 4px;
`;
