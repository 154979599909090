import { Button, Popover, Typography } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import styled from "styled-components";
import { SessionStorageType } from "../../../../../../constants/storage";
import { sendGaEvent } from "../../../../../../ga/ga";
import { GaHotjarSurveyModel } from "../../../../../../ga/models/hotjar-surveys";
import { useAppDispatch } from "../../../../../../hooks/store";
import { useTranslation } from "../../../../../../i18n";
import { removeStorageItem, storageKeys } from "../../../../../../store/storage";

export const CompliancePopover: FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const { children, ...htmlProps } = props;
  const { t } = useTranslation(["context_app"]);
  const dispatch = useAppDispatch();
  const gaEvent: GaHotjarSurveyModel = {
    eventCategory: "Compliance-Dismiss",
    eventAction: "Launch-Hotjar-Survey"
  };

  return (
    <div style={{ position: "relative" }} {...htmlProps}>
      <Popover
        visible={true}
        placement="bottom"
        popoverTheme="alternate"
        content={
          <Content>
            <Subtitle level="2">{t("context_app:compliance_popover.title")}</Subtitle>
            <div style={{ paddingBottom: "1.5rem" }}>
              <Typography.Text color="primary-inverted" level="2">
                {t("context_app:compliance_popover.text")}
              </Typography.Text>
            </div>
            <Button
              type="white"
              size="small"
              onClick={() => {
                dispatch(
                  removeStorageItem({
                    key: SessionStorageType.HOME_COMPLIANCE_OLD_TRACKER_POPOVER,
                    type: storageKeys.sessionStorage
                  })
                );
                sendGaEvent(gaEvent);
              }}
            >
              {t("context_app:compliance_popover.button")}
            </Button>
          </Content>
        }
      >
        {props.children}
      </Popover>
    </div>
  );
};

const Content = styled.div`
  padding: 0.25rem;
  width: 20rem;
`;

const Subtitle = styled(Typography.Subtitle)`
  color: ${props => props.theme.colors?.white};
  padding-bottom: 0.625rem;
`;
