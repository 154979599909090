import { AccreditationLicense, ViewTypeSelection } from "@cpe/models/accreditation-license";
import { LicensesType } from "@cpe/models/regulators-license";
import * as FetchUtil from "../../fetch-util";
export type BulkLicense = {
  regulatorLicenseVersion: number;
  ptinNumber?: string;
  issueDate?: string;
  expirationDate?: string;
  reportingDate?: string;
  reciprocalExemption?: boolean;
  birthMonth?: number;
  viewTypeSelection?: ViewTypeSelection;
};
export type CreateLicenseBulkRequest = {
  template: BulkLicense;
  userLicenses: { [Key: string]: BulkLicense };
};

export type BulkLicenseDetails = {
  template: AccountLicenseWithLicenseResponse;
  userLicenses: {
    [userId: string]: AccountLicenseResponse;
  };
};

export type AccountLicenseWithLicenseResponse = AccountLicenseResponse & {
  license: LicensesType;
};

export type AccountLicenseResponse = {
  regulatorName: string;
  regulatorLicenseVersion: number;
  ptinNumber?: string;
  issueDate?: string;
  expirationDate?: string;
  reportingDate?: string;
  pendingReview?: boolean;
  lastEditedBy?: LicenseEditedBy;
  enabled: boolean;
  reciprocalExemption?: boolean;
  birthMonth?: number;
  viewTypeSelection?: ViewTypeSelection;
};

enum LicenseEditedBy {
  "AICPA_IMPORT",
  "CLIENT_ADMIN",
  "OWNER"
}

export class AccreditationLicenseService {
  static delete(licenseId: string, userId?: string): Promise<FetchUtil.HttpResponse<void>> {
    if (!userId) {
      return FetchUtil.Delete(accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId), {});
    }
    return FetchUtil.Delete(
      `${accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId)}?userId=${userId}`,
      {}
    );
  }
  static get(licenseId: string, userId?: string): Promise<FetchUtil.HttpResponse<AccreditationLicense>> {
    if (userId) {
      return FetchUtil.get(
        `${accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId)}?userId=${userId}`
      );
    }
    return FetchUtil.get(accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId));
  }
  static enable(licenseId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(accreditaitonLicenseApiMatchers.enable.replace(":licenseId", licenseId), {});
  }
  static disable(licenseId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(accreditaitonLicenseApiMatchers.enable.replace(":licenseId", licenseId), {});
  }
  static update(
    accreditationLicense: AccreditationLicense,
    licenseId: string,
    userId?: string
  ): Promise<FetchUtil.HttpResponse<void>> {
    const { license, ...resumedLicense } = accreditationLicense;
    if (!userId) {
      return FetchUtil.put(accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId), resumedLicense);
    }
    return FetchUtil.put(
      `${accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId)}?userId=${userId}`,
      resumedLicense
    );
  }
  static create(license: AccreditationLicense, licenseId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(accreditaitonLicenseApiMatchers.license.replace(":licenseId", licenseId), license);
  }
  static getLicenses(): Promise<FetchUtil.HttpResponse<AccreditationLicense[]>> {
    return FetchUtil.get(accreditaitonLicenseApiMatchers.licenses);
  }
  static create_bulk(licenses: CreateLicenseBulkRequest): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(accreditaitonLicenseApiMatchers.licenses_bulk, licenses);
  }
  static get_bulk(regulatorLicenseId: string): Promise<FetchUtil.HttpResponse<BulkLicenseDetails>> {
    return FetchUtil.get(
      accreditaitonLicenseApiMatchers.getLicense_bulk.replace(":regulatorLicenseId", regulatorLicenseId)
    );
  }
  static edit_bulk(
    regulatorLicenseId: string,
    licenses: CreateLicenseBulkRequest
  ): Promise<FetchUtil.HttpResponse<BulkLicenseDetails>> {
    return FetchUtil.put(
      accreditaitonLicenseApiMatchers.edit_bulk.replace(":regulatorLicenseId", regulatorLicenseId),
      licenses
    );
  }
  static delete_bulk(regulatorLicenseId: string) {
    return FetchUtil.Delete(
      accreditaitonLicenseApiMatchers.delete_bulk.replace(":regulatorLicenseId", regulatorLicenseId),
      {}
    );
  }
}

export const accreditaitonLicenseApiMatchers = {
  license: `/api/v1/compliance/licenses/:licenseId`,
  enable: `/api/v1/compliance/licenses/:licenseId/enable`,
  licenses_bulk: "/api/v1/compliance/licenses/bulk",
  getLicense_bulk: "/api/v1/compliance/licenses/bulk/:regulatorLicenseId",
  edit_bulk: "/api/v1/compliance/licenses/bulk/:regulatorLicenseId",
  delete_bulk: "/api/v1/compliance/licenses/bulk/:regulatorLicenseId",
  licenses: "/api/v1/compliance/licenses"
};
