import { NavigationLink } from "@cpe/models/navigation-link";
import _ from "lodash/fp";
import React, { ReactChild, ReactFragment, ReactPortal } from "react";
import { createRoot } from "react-dom/client";
import Measure, { BoundingRect, withContentRect } from "react-measure";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { store } from "../../../../../../store";
import { ComplianceDisclaimerProvider } from "../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";
import { LinkItem } from "./link-item";
import { LinkWithChildren } from "./link-with-children";
import { MoreNavigation } from "./more-navigation";
import { useNavigationLinkGroup } from "./use-navigation-link-group";

// For futil
const partitionIndexed = (f: any, arr: []) => {
  // @ts-expect-error known issue https://github.com/DefinitelyTyped/DefinitelyTyped/issues/27194
  const match = _.takeWhile.convert({ cap: false })(f, arr);
  const rest = _.takeRight(arr.length - match.length, arr);
  return [match, rest];
};
// Just wrapping up the Measure API a little nicer
const withBounds = (
  Component: React.FC<{
    bounds: BoundingRect | undefined;
    children?: React.ReactNode;
    className?: string;
    navigationLinks: NavigationLink[];
  }>
) =>
  withContentRect("bounds")<
    {
      className?: string;
      navigationLinks: NavigationLink[];
      overflowcomponentsize: number;
    } & React.HTMLAttributes<HTMLDivElement>
  >(({ measureRef, contentRect, children, ...props }) => {
    return (
      <div className={props.className} ref={measureRef}>
        <Component bounds={contentRect.bounds} {...props} />
      </div>
    );
  });

// This is the main component
export const OverflowMenu = withBounds(({ bounds, children, className, navigationLinks, ...props }) => {
  // Use Child Bounds - we measure the children off screen to avoid an infinite loop
  const [childBounds] = React.useState<any>([]);
  const location = useLocation();
  const { groupedNavLinks } = useNavigationLinkGroup(navigationLinks);

  React.useLayoutEffect(() => {
    const offscreen = document.createElement("div");
    offscreen.style.position = "absolute";
    offscreen.style.top = "-999999999px";
    offscreen.style.left = "-999999999px";
    offscreen.style.opacity = "0";
    document.body.appendChild(offscreen);
    createRoot(offscreen).render(
      <Provider store={store}>
        <ComplianceDisclaimerProvider>
          <Router>
            {navigationLinks.map((link, index) => {
              if (location && location.pathname) {
                return (
                  <Measure
                    key={index}
                    bounds
                    onResize={contentRect => {
                      childBounds[index] = contentRect.bounds;
                    }}
                  >
                    {({ measureRef }: any) => (
                      <span ref={measureRef}>
                        {link.children ? (
                          <LinkWithChildren link={link} />
                        ) : (
                          <LinkItem role="listitem" link={link} data-qa-label={link.permission}>
                            {link.description}
                          </LinkItem>
                        )}
                      </span>
                    )}
                  </Measure>
                );
              }
              return null;
            })}
          </Router>
        </ComplianceDisclaimerProvider>
      </Provider>
    );
    return () => {
      document.body.removeChild(offscreen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds?.width || 0]);
  const { overflowcomponentsize, measure, ...newProps } = props as any;

  const overFlowButtonWidth = overflowcomponentsize;
  let sum = 0;
  const [fit, overflow] = partitionIndexed((child: any, i: number) => {
    sum += _.get("width", childBounds[i]) || overFlowButtonWidth - 11;
    return sum <= (bounds?.width || 0) - overFlowButtonWidth;
  }, groupedNavLinks as []);

  return (
    <div className={className} {...newProps}>
      {fit.map((link: NavigationLink, index: number) => {
        if (link.children) {
          return <LinkWithChildren link={link} key={index} />;
        }
        return (
          <LinkItem key={index} role="listitem" link={link} data-qa-label={link.permission}>
            {link.description}
          </LinkItem>
        );
      })}
      {overflow.length ? <MoreNavigation items={overflow} /> : null}
    </div>
  );
});
type ReactElements = Array<ReactChild | ReactFragment | ReactPortal>;
export type OverflowComponentType = {
  overflow: ReactElements[];
};
