import React, { FC } from "react";
import styled from "styled-components";
type Props = {
  open: boolean;
};
export const HamburgerIcon: FC<Props> = props => {
  const { open } = props;

  return (
    <Container>
      <FirstBar open={open} />
      <Bar open={open} />
      <Bar open={open} />
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
`;

const Bar = styled.span<Props>`
  transition: background-color 0.5s linear;
  background-color: ${props => (props.open ? props.theme.colors.brand02.default : "#939598")};
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  margin-bottom: 4px;
`;

const FirstBar = styled(Bar)`
  margin-top: 6px;
`;
