import { defaultTheme, DSTheme as BDSTheme } from "@beckersdp/becker-ds";
import { createGlobalStyle, DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme extends BDSTheme {
    [key: string]: any;
  }
}

export const theme: DefaultTheme = {
  ...defaultTheme,
  stackOrder: {
    ...defaultTheme.stackOrder
  },
  pages: {
    home: {
      courseCard: {
        heightDesktop: "15.189rem",
        heightMobile: "13.625rem"
      }
    }
  }
};

export const GlobalStyles = createGlobalStyle`
  
  body,
  html, #root {
    margin:0;
    padding:0;
    position: absolute;
    width: 100%;
    box-sizing:border-box;
    
  }

  #root, cpe-header, cpe-buy-button, cpe-subscribe-button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ${props => props.theme.typography.types.paragraph1};
    font-size: inherit;
  }
  @media (prefers-reduced-motion) {
    *,
    *::before,
    *::after {
      transition: none !important;
      animation: none !important;
    }
  }
`;
