import React, { createContext, FC, useContext } from "react";

type SectionUtilsDataModel = {
  onRegistry: () => void;
};

type Props = {
  children?: React.ReactNode | undefined;
  onRegistry: () => void;
};

const SectionUtilsContext = createContext<SectionUtilsDataModel | undefined>(undefined);

export const SectionUtilsProvider: FC<Props & { children: React.ReactNode }> = props => {
  const { onRegistry, ...rest } = props;

  return <SectionUtilsContext.Provider value={{ onRegistry }} {...rest} />;
};

/**
 * Uses the context provided by the `SectionUtilsContext`.
 *
 * @returns {SectionUtilsContextValue} - The provided context values.
 */
export const useSectionUtilsContext = (): SectionUtilsDataModel => {
  const context = useContext(SectionUtilsContext);

  if (context === undefined) {
    throw new Error("useSectionUtilsContext must be used within a SectionUtilsContextProvider");
  }

  return context;
};

export default SectionUtilsContext;
