import { AnnualsModel, ComplianceCreditsSummary, ReportingPeriodsModel } from "@cpe/models/credits-license";
import { CreditsSummaryService } from "@cpe/services/compliance/credits";
import { SuccessCodeRegex } from "@cpe/services/fetch-util";
import debounce from "lodash/debounce";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useFeatureFlags } from "../../../../components/feature-flag/use-feature-flags";

export type ComplianceCreditsState = {
  complianceCredits: ComplianceCreditsSummary[] | undefined;
  currentReportingPeriod: ReportingPeriodsModel | undefined;
  currentLicense: ComplianceCreditsSummary | undefined;
  currentAnnuals: AnnualsModel | undefined;
  setCurrentLicenseIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrentAnnualsIndex: React.Dispatch<React.SetStateAction<number>>;
  currentAnnualsIndex: number;
  currentLicenseIndex: number;
  isLoading: boolean;
};

export const useComplianceCredits = (): ComplianceCreditsState => {
  const [complianceCredits, setComplianceCredits] = useState<ComplianceCreditsSummary[] | undefined>();
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriodsModel | undefined>();
  const [currentLicenseIndex, setCurrentLicenseIndex] = useState(0);
  const [currentLicense, setCurrentLicense] = useState<ComplianceCreditsSummary | undefined>();
  const [currentAnnualsIndex, setCurrentAnnualsIndex] = useState(0);
  const [currentAnnuals, setCurrentAnnuals] = useState<AnnualsModel | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { isFFActive, isLoading: isLoadingFF } = useFeatureFlags();

  const refreshData = async () => {
    setIsLoading(true);
    const response = await CreditsSummaryService.get();
    if (response.status.toString().match(SuccessCodeRegex)) {
      const summaries = response?.parsedBody?.licenseSummaries as ComplianceCreditsSummary[];
      if (
        isFFActive("STUDENT_REVIEW_PENDING_IMPORTED_LICENSES") ||
        isFFActive("CLIENT_ADMIN_REVIEW_PENDING_IMPORTED_LICENSES")
      ) {
        const complianceCreditsOrderByReviewLicense = summaries?.sort((a, b) =>
          (a?.pendingReview || 0) > (b?.pendingReview || 0) ? -1 : 0
        );
        setComplianceCredits(complianceCreditsOrderByReviewLicense);
      } else {
        setComplianceCredits(summaries);
      }
    }
  };
  const debouncedRefresh = debounce(() => {
    refreshData();
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    if (isLoadingFF === false) {
      debouncedRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFF]);

  useEffect(() => {
    // NOTE: exported components handle redenrer diferently we need to cancel request when resizing the window to avoid making to much http requests
    window.addEventListener("resize", debouncedRefresh.cancel);

    return () => {
      window.removeEventListener("resize", debouncedRefresh.cancel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (complianceCredits) {
      setCurrentLicense(complianceCredits[currentLicenseIndex]);
    }
  }, [currentLicenseIndex, complianceCredits]);

  useEffect(() => {
    if (complianceCredits) {
      setCurrentAnnuals(currentReportingPeriod?.annuals?.[currentAnnualsIndex]);
    }
  }, [currentReportingPeriod, currentAnnualsIndex, complianceCredits]);

  useEffect(() => {
    if (complianceCredits) {
      const currentPeriod = complianceCredits?.[currentLicenseIndex]?.reportingPeriods?.filter(reportingPeriod =>
        moment().isBetween(reportingPeriod.start, reportingPeriod.end, "day", "[]")
      )[0];
      setCurrentReportingPeriod(currentPeriod);
      currentPeriod?.annuals?.map((annual, index) => {
        if (moment().isBetween(annual.start, annual.end, "day", "[]")) {
          setCurrentAnnualsIndex(index);
        }
      });
    }
  }, [complianceCredits, currentLicenseIndex]);

  return {
    currentAnnuals,
    complianceCredits,
    currentReportingPeriod,
    currentLicense,
    setCurrentLicenseIndex,
    setCurrentAnnualsIndex,
    currentLicenseIndex,
    currentAnnualsIndex,
    isLoading
  };
};
