import { FeedbackType } from "@cpe/models/feedback";
import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { usePopupMessages } from "../../pages/home/components/popup-messages/use-popup-messages";
import { availableRoutes } from "../../router/routes";

type ContextType = {
  agreeWithComplianceDisclaimer: () => void;
  agreeWithComplianceDisclaimerAndRedirect: () => void;
  closeComplianceDisclaimer: () => void;
  shouldShowComplianceDisclaimer: () => boolean;
  openComplianceDisclaimer: (onlyClose?: false, redirectUrl?: string) => void;
  visible: boolean;
  onlyCloseModal: boolean;
};

const ComplianceDisclaimerContext = createContext<ContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const ComplianceDisclaimerProvider: FC<Props> = props => {
  const [visible, setVisible] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [onlyCloseModal, setOnlyCloseModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const { setRemoteDismiss, feebacksToShow } = usePopupMessages();

  useEffect(() => {
    setVisible(visibility);
  }, [visibility]);
  return (
    <ComplianceDisclaimerContext.Provider
      {...props}
      value={{
        agreeWithComplianceDisclaimerAndRedirect: () => {
          setRemoteDismiss(FeedbackType["COMPLIANCE_DISCLAIMER"]);
          window.location.href = redirectUrl;
        },
        agreeWithComplianceDisclaimer: () => {
          setRemoteDismiss(FeedbackType["COMPLIANCE_DISCLAIMER"]);
        },
        closeComplianceDisclaimer: () => setVisibility(false),
        shouldShowComplianceDisclaimer: () => {
          return feebacksToShow.filter(feedback => feedback.type === FeedbackType["COMPLIANCE_DISCLAIMER"]).length > 0;
        },
        openComplianceDisclaimer: (onlyClose = false, redirectUrl = availableRoutes.compliance) => {
          setRedirectUrl(redirectUrl);
          setOnlyCloseModal(onlyClose);
          setVisibility(true);
        },
        visible,
        onlyCloseModal
      }}
    />
  );
};

/**
 * Uses the context provided by the `ComplianceDisclaimerContext`.
 *
 * @returns {ComplianceDisclaimerContextValue} - The provided context values.
 */
export const useComplianceDisclaimerContext = (): ContextType => {
  const context = useContext(ComplianceDisclaimerContext);

  if (context === undefined) {
    throw new Error("useComplianceDisclaimerContext must be used within a ComplianceDisclaimerContextProvider");
  }

  return context;
};

export default useComplianceDisclaimerContext;
