import { useEffect } from "react";

export const usePreventScroll = (isToPreventScroll: boolean) => {
  useEffect(() => {
    isToPreventScroll ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isToPreventScroll]);
};
