import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useUser } from "../../../../../../hooks";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { useAppDispatch } from "../../../../../../store";
import { Cart } from "../../../../../../store/cart/thunks";
import { ImpersonateBanner } from "../../../../../impersonate-banner";
import MiniCartToggle from "../mini-cart-toggle";
import { NavPopover } from "./components/nav-popover";
// eslint-disable-next-line
const BeckerLogo = require("../../../../../../assets/logo.svg");

export const MobileHeader: FC = props => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null); // Popper's ref for mini cart
  const { user } = useUser();
  const dispatch = useAppDispatch();
  useEffect(() => {
    user.shoppingCart && dispatch(Cart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.shoppingCart]);

  return (
    <HeaderContainer>
      <ImpersonateBanner />
      <StyledHeader data-qa-label={PageQALabels.header}>
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        {user.shoppingCart && (
          <MiniCartToggle setReferenceElement={setReferenceElement} referenceElement={referenceElement} />
        )}
        <NavPopover />
      </StyledHeader>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 0px;
  display: flex;
  align-items: end;
  padding: 16px;
  gap: 24px;
`;

const LogoContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledLogo = styled(BeckerLogo)`
  max-height: 100%;
  max-width: 105px;
  height: 24px;
  flex-shrink: 0;
`;
