import { EDSGlobalStyles, ScreenContext, Tooltip } from "@beckersdp/becker-ds";
import React, { FC, Suspense } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ComplianceLegalDisclaimer } from "../src/components/compliance-legal-disclaimer";
import { ComplianceDisclaimerProvider } from "../src/components/compliance-legal-disclaimer/compliance-disclaimer-provider";
import { useScreen } from "../src/hooks/use-screen";
import { PrivateRoute } from "../src/router/private-route";
import { PageId } from "../src/router/types";
import { store } from "../src/store";
import { GlobalStyles, theme } from "../src/theme";

type Props = {
  withAuth?: boolean;
  pageId?: PageId;
  updateStore?: boolean;
  children: React.ReactNode;
};

export const CPEWrapper: FC<Props> = props => {
  const { updateStore = false } = props;
  return (
    <ThemeProvider theme={theme}>
      <ScreenContext.Provider value={useScreen()}>
        <Provider store={store}>
          <Suspense fallback={<div>Loading...</div>}>
            <Router>
              <Switch>
                <ComplianceDisclaimerProvider>
                  {props.withAuth ? (
                    <PrivateRoute
                      path="*"
                      component={() => <>{props.children}</>}
                      pageId={props.pageId || "HOME"}
                      updateStore={updateStore}
                    />
                  ) : (
                    <Route path="*" component={() => <>{props.children}</>} />
                  )}
                  <ComplianceLegalDisclaimer />
                </ComplianceDisclaimerProvider>
                <Tooltip.Manager />
              </Switch>
            </Router>
            <EDSGlobalStyles />
            <GlobalStyles />
          </Suspense>
        </Provider>
      </ScreenContext.Provider>
    </ThemeProvider>
  );
};
