import { Button, Icon } from "@beckersdp/becker-ds";
import { ButtonProps } from "@beckersdp/becker-ds/dist/button/types";
import React, { FC } from "react";
import styled from "styled-components";
import { useCart } from "../../../../hooks";
import { useTranslation } from "../../../../i18n";
import { courseCardQALabels } from "../../../../page-objects/components/course-card/qa-labels";
import { useCourseCardContext } from "../../course-card-provider";

type Props = {
  size: ButtonProps["size"];
  className?: string;
};

export const _BuyButton: FC<Props> = props => {
  const { size, className } = props;
  const { t } = useTranslation(["courseCard"]);
  const cart = useCart();
  const { addToCart, addingToCart, course } = useCourseCardContext();
  const isInCart = Boolean(cart?.items?.find(item => item.productId === course.productId));

  return (
    <Button
      title={t("courseCard:buy_title")}
      aria-label={t("courseCard:buy_title")}
      qaLabel={courseCardQALabels.addToCartButton}
      type="onboarding"
      size={size}
      loading={addingToCart}
      onClick={addToCart}
      icon="cart"
      disabled={isInCart && !addingToCart}
      className={className}
    >
      {isInCart ? (
        <Icon width="0.9375rem" name="check" />
      ) : (
        t("courseCard:card_buy_cart", { price: parseFloat(course.purchaseInfo?.price.toString() || "0").toFixed(2) })
      )}
    </Button>
  );
};

export const BuyButton = styled(_BuyButton)`
  svg {
    flex-shrink: 0;
  }
  div {
    flex-grow: 0;
  }
  justify-content: center;
`;
