export enum ToastIdsEnum {
  "COMPLIANCE_OLD_TRACKER" = "compliance_old_tracker",
  "CERTIFICATE_ADDED_SUCCESSFULLY" = "certificate_added_successfully",
  "EXTERNAL_PROGRAM_ADDED_SUCCESSFULLY" = "program_added_successfully",
  "EXTERNAL_PROGRAM_EDITED_SUCCESSFULLY" = "program_edited_successfully",
  "EXTERNAL_PROGRAM_DELETED_SUCCESSFULLY" = "program_deleted_successfully",
  "L_AND_J_LICENSE_DELETED_SUCCESSFULLY" = "l&j_license_deleted_successfully"
}

export enum ToastTypesEnum {
  "FEEDBACK" = "feedback",
  "INFORMATIVE" = "informative",
  "NOTIFICATION" = "notification"
}
