import { featureFlagsApi } from "@cpe/services/feature-flags/feature-flags.service";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import activeFeatureFlagsSlice from "./active-feature-flags/index";
import cartSlice from "./cart";
import complianceUsersSlice from "./compliance-users";
import coursesSlice from "./courses";
import eulaSlice from "./eula";
import feedbackSlice from "./feedback";
import navigationSlice from "./navigation";
import regulatorsSlice from "./regulators";
import sectionsSlice from "./sections";
import storageSlice from "./storage";
import toastSlice from "./toasts";
import userSlice from "./user";

export const reducer = {
  user: userSlice,
  navigation: navigationSlice,
  sections: sectionsSlice,
  cart: cartSlice,
  courses: coursesSlice,
  regulators: regulatorsSlice,
  storage: storageSlice,
  toasts: toastSlice,
  complianceUsers: complianceUsersSlice,
  activeFeatureFlags: activeFeatureFlagsSlice,
  feedback: feedbackSlice,
  eula: eulaSlice,
  [featureFlagsApi.reducerPath]: featureFlagsApi.reducer
};

export const middleware = (getDefaultMiddleware: any) => getDefaultMiddleware().concat(featureFlagsApi.middleware);
export const store = configureStore({
  reducer: {
    user: userSlice,
    navigation: navigationSlice,
    sections: sectionsSlice,
    cart: cartSlice,
    courses: coursesSlice,
    regulators: regulatorsSlice,
    storage: storageSlice,
    toasts: toastSlice,
    complianceUsers: complianceUsersSlice,
    activeFeatureFlags: activeFeatureFlagsSlice,
    feedback: feedbackSlice,
    eula: eulaSlice,
    [featureFlagsApi.reducerPath]: featureFlagsApi.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(featureFlagsApi.middleware),
  devTools: process.env.NODE_ENV === "development"
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
