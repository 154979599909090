import noop from "lodash/noop";
import React from "react";
import ReactDOM from "react-dom/client";
import { StyleSheetManager } from "styled-components";
import { CourseCardProvider } from "../../src/components/course-card/course-card-provider";
import { SectionUtilsProvider } from "../../src/contexts/section-utils-context";
import { CPEWrapper } from "../cpe-wrapper";
import { SubscriptionButton } from "./subscription-button";

type Size = "small" | "medium" | "large";

class CPESubscriptionButton extends HTMLElement {
  private readonly styleHost: HTMLElement;
  private readonly mountPoint: HTMLElement;
  constructor() {
    super();
    this.mountPoint = document.createElement("div");
  }
  connectedCallback() {
    ReactDOM.createRoot(this).render(
      <StyleSheetManager disableCSSOMInjection={true}>
        <CPEWrapper>
          <SectionUtilsProvider onRegistry={async () => noop()}>
            <CourseCardProvider
              course={JSON.parse(this.getAttribute("course") as string)}
              onToggleSave={async () => noop()}
            >
              <SubscriptionButton size={this.getAttribute("size") as Size} />
            </CourseCardProvider>
          </SectionUtilsProvider>
        </CPEWrapper>
      </StyleSheetManager>
    );
  }
  disconnectedCallback() {
    this.shadowRoot?.removeChild(this.mountPoint);
    this.shadowRoot?.removeChild(this.styleHost);
  }
}
customElements.define("cpe-subscribe-button", CPESubscriptionButton);
