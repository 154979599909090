import { EulaData } from "@cpe/models/user";
import { createSlice } from "@reduxjs/toolkit";
import { Eula } from "./thunks";

export interface EulaState {
  loading: boolean;
  error: string | null;
  eula?: EulaData;
}

export const initialState: EulaState = {
  loading: false,
  error: null,
  eula: undefined
};

export const eulaSlice = createSlice({
  name: "eula",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(Eula.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message;
      } else {
        state.eula = {
          body: response?.body || "",
          subject: response?.subject || ""
        };
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(Eula.rejected, (state, action) => {
      state.loading = false;
      state.error = "Something is wrong.";
      state.eula = undefined;
    });
    builder.addCase(Eula.pending, (state, action) => {
      state.error = "";
      state.loading = true;
    });
  }
});

export default eulaSlice.reducer;
