import React, { FC, RefObject, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { useAppDispatch } from "../../../../../../hooks/store";
import { useClickOutside } from "../../../../../../hooks/use-click-outside";
import { useDeviceBreakpoint } from "../../../../../../hooks/use-device-breakpoint";
import { MiniCartQALabels } from "../../../../../../page-objects/components/mini-cart/qa-labels";
import { setMiniCartOpenStatus } from "../../../../../../store/cart";
import { media } from "../../../../../../utils/media-queries";
import MiniCart from "../../../../../cart/mini-cart";

type Props = {
  referenceElement: HTMLElement | null;
};

const MiniCartToggled: FC<Props> = ({ referenceElement }) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { isPhone } = useDeviceBreakpoint();

  const dispatch = useAppDispatch();
  const toogleCart = () => dispatch(setMiniCartOpenStatus());

  /* Suggested on https://popper.js.org/react-popper/v2/faq/ (21 Oct 2021),
   ** due to render loop whenever a function is defined inside popper configuration. */
  const customModifier = useMemo(
    () => [
      {
        name: "arrow",
        options: {
          element: arrowElement
        }
      },
      {
        name: "offset",
        options: {
          offset: ({ placement }: { placement: string }) => {
            if (!isPhone) {
              if (placement === "bottom-start") {
                return [-30, 0];
              }
              if (placement === "bottom-end") {
                return [30, 0];
              }
              return [];
            }
            return [0, 7];
          }
        }
      }
    ],
    [arrowElement, isPhone]
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "absolute",
    modifiers: customModifier
  });

  const popperElementAsRefCurrent = (popperElement as any) as RefObject<HTMLElement>;
  const referenceElementAsRefCurrent = (referenceElement as any) as RefObject<HTMLElement>;

  useClickOutside(toogleCart, [popperElementAsRefCurrent, referenceElementAsRefCurrent]);

  return (
    <Div
      ref={setPopperElement}
      style={styles.popper}
      data-qa-label={MiniCartQALabels.toggledWrapper}
      {...attributes.popper}
    >
      <div ref={setArrowElement} style={styles.arrow} id="arrow" />
      <MiniCart />
    </Div>
  );
};

const Div = styled.div`
  ${media.phoneHD.max} {
    padding: 0 1rem;
  }

  #arrow {
    width: 0.625rem; /* 10px */
    height: 0.625rem; /* 10px */
    &:after {
      content: "";
      background-color: ${props => props.theme.colors.brand01[800]};
      position: absolute;
      top: -0.3125rem; /* 5px */
      left: 0;
      transform: rotate(45deg);
      width: 0.625rem; /* 10px */
      height: 0.625rem; /* 10px */
    }
  }
`;

export default MiniCartToggled;
