import { Popover } from "@beckersdp/becker-ds";
import React, { FC, RefObject, useState } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../../../../../../../hooks/use-click-outside";
import { PageQALabels } from "../../../../../../../../page-objects/components/page/qa-labels";
import { UserAvatar } from "../../../../../../../user-avatar";
import { AccountSetting } from "../../../account-settings";

export const AccountLinks: FC = props => {
  const [isOpen, setOpen] = useState(false);
  const [accountElement, setAccountElement] = useState<HTMLElement | null>(null);
  const accountElementAsRefCurrent = (accountElement as any) as RefObject<HTMLElement>;
  useClickOutside(() => {
    setOpen(false);
  }, [accountElementAsRefCurrent]);
  return (
    <div ref={setAccountElement}>
      <Popover
        visible={isOpen}
        offset={[0, 10]}
        placement="bottom-end"
        triggerEvents={["click", "focus"]}
        content={<AccountSetting />}
      >
        <LI role="listitem" tabIndex={0} data-qa-label={PageQALabels.accountDropdown} onClick={() => setOpen(!isOpen)}>
          <UserAvatar size="small" />
        </LI>
      </Popover>
    </div>
  );
};

const LI = styled.div`
  box-sizing: border-box;
  height: 62px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-top: 12px;
  margin-bottom: 12px;
  gap: 10px;
  color: ${props => props.theme.colors.brand01[700]};

  & :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  &&:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.brand01.default};
  }
  &.active {
    color: ${props => props.theme.colors.brand01.default};
  }
`;
