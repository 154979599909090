import { NavigationElement, NavigationGroup, NavigationItem } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import styled from "styled-components";
import { MainLinkGroup } from "./main-link-group";
import { MainLinkItem } from "./main-link-item";

type Props = {
  items: NavigationElement;
};

export const MainLinks: FC<Props> = props => {
  const { items } = props;
  return (
    <UL role="list">
      {items.map(item => {
        if ((item as NavigationGroup)?.items?.length > 0) {
          return <MainLinkGroup key={item.label} title={item.label} item={item as NavigationGroup} />;
        }
        return (
          <React.Fragment key={item.label}>
            <MainLinkItem item={item as NavigationItem} />
          </React.Fragment>
        );
      })}
    </UL>
  );
};

const UL = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 32px;
  list-style-type: none;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;
