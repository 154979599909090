import { Modal } from "@beckersdp/becker-ds";
import { Promo, PromoProductsType } from "@cpe/models/promos";
import React, { FC } from "react";
import { UpgradeModalQALabels } from "../../page-objects/components/upgrade-modal/qa-labels";
import { EssentialsPlusCompliance } from "./essentials_plus_compliance";
import { SelectPlusCompliance } from "./select_plus_compliance";
import { PromoModalProps } from "./types";
import { UpgradeEssentials } from "./upgrade-essentials";
import { UpgradeEssentialsAfter90Days } from "./upgrade-essentials-after-90-days";
import { UpgradeNoSubscription } from "./upgrade-no-subscription";
import { UpgradeSelect } from "./upgrade-select";
import { UpgradeSelectAfter90Days } from "./upgrade-select-after-90-days";

type Props = {
  visible: boolean;
  onClose?: () => void;
  forceUpgradeModal?: boolean;
  promos: Promo[];
};

type UpgradePromoProduct = Exclude<PromoProductsType, "COMPLIANCE" | "COMPLIANCE_DIRECT" | "PRIME_DIRECT">;

export const ComplianceUpgradeModal: FC<Props> = props => {
  const { promos, forceUpgradeModal } = props;

  const CT = promos?.filter(promo => promo?.productType === "COMPLIANCE")?.[0];
  const CTDirect = promos?.filter(promo => promo?.productType === "COMPLIANCE_DIRECT")?.[0];
  const upgrade = promos?.filter(promo => !["COMPLIANCE", "COMPLIANCE_DIRECT"].includes(promo?.productType))?.[0];
  const crossell = promos?.filter(promo =>
    ["ESSENTIALS_PLUS_COMPLIANCE", "SELECT_PLUS_COMPLIANCE"].includes(promo?.productType)
  )?.[0];

  const PromoMap: {
    [key in UpgradePromoProduct]: React.FC<PromoModalProps> | null;
  } = {
    UPGRADE_SELECT_PRIME: UpgradeSelect,
    UPGRADE_ESSENTIALS_PRIME: UpgradeEssentials,
    PRIME: UpgradeNoSubscription,
    SELECT_PLUS_COMPLIANCE: SelectPlusCompliance,
    ESSENTIALS_PLUS_COMPLIANCE: EssentialsPlusCompliance,
    UPGRADE_ESSENTIALS_PRIME_AFTER_90_DAYS: UpgradeEssentialsAfter90Days,
    UPGRADE_SELECT_PRIME_AFTER_90_DAYS: UpgradeSelectAfter90Days
  };
  if (promos.length === 0) {
    return null;
  }
  let Component: React.FC<PromoModalProps> | null;
  if (forceUpgradeModal) {
    Component = PromoMap[upgrade.productType as UpgradePromoProduct];
  } else {
    Component = PromoMap[(crossell?.productType || upgrade.productType) as UpgradePromoProduct];
  }
  if (!Component) {
    return null;
  }
  return (
    <Modal size="large" {...props} data-qa-label={UpgradeModalQALabels.container}>
      <Component ct={crossell && !forceUpgradeModal ? CTDirect : CT} upgrade={upgrade} crossell={crossell} />
    </Modal>
  );
};
