import i18next from "i18next";
import {
  initReactI18next,
  Namespace,
  Resources,
  useTranslation as I18nUseTranslation,
  UseTranslationOptions,
  UseTranslationResponse
} from "react-i18next";

export const supportedLanguages = ["en"] as const;
type supportedLanguages = typeof supportedLanguages[number];

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    returnObjects: true,
    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export const useTranslation = <N extends Namespace<keyof Resources>>(
  ns?: N | undefined,
  options?: UseTranslationOptions | undefined
): UseTranslationResponse<N> => {
  loadTranslationIfNeeded(ns);
  return I18nUseTranslation(ns);
};

export const loadTranslationIfNeeded = (ns: any): Namespace<any> => {
  ns.forEach(async (namespace: string) => {
    const currentLanguage = i18next.language;
    if (currentLanguage && !i18next?.hasResourceBundle(currentLanguage, namespace)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      i18next.addResourceBundle(currentLanguage, namespace, require(`./locales/${currentLanguage}/${namespace}.json`));
    }
  });
};
