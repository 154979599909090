import { Modal } from "@beckersdp/becker-ds";
import { Transactional } from "@beckersdp/becker-ds/dist/dialog/transactional/transactional";
import React, { FC } from "react";
import { useTranslation } from "../../i18n";
import useComplianceDisclaimerContext from "./compliance-disclaimer-provider";

type Props = {
  onlyCloseModal?: boolean;
};

export const ComplianceLegalDisclaimer: FC<Props> = props => {
  const { t } = useTranslation(["complianceTracking"]);
  const {
    agreeWithComplianceDisclaimerAndRedirect,
    closeComplianceDisclaimer,
    visible,
    onlyCloseModal
  } = useComplianceDisclaimerContext();

  return (
    <>
      {!onlyCloseModal ? (
        <Transactional
          okText={t("complianceTracking:complianceDisclaimer.action.agree")}
          onCancel={closeComplianceDisclaimer}
          onClose={closeComplianceDisclaimer}
          onOk={agreeWithComplianceDisclaimerAndRedirect}
          maxWidth={930}
          size="large"
          title={t("complianceTracking:complianceDisclaimer.title")}
          visible={visible}
        >
          <Modal.BodyText>
            <div style={{ marginTop: "13px", marginBottom: "17px" }}>
              <div style={{ marginBottom: "21px" }}>{t("complianceTracking:complianceDisclaimer.action.message1")}</div>
              {t("complianceTracking:complianceDisclaimer.action.message2")}
            </div>
          </Modal.BodyText>
        </Transactional>
      ) : (
        <Transactional
          okText={t("complianceTracking:complianceDisclaimer.action.close")}
          onClose={closeComplianceDisclaimer}
          onOk={closeComplianceDisclaimer}
          extraOkProps={{
            type: "onboarding",
            size: "small"
          }}
          maxWidth={930}
          size="large"
          title={t("complianceTracking:complianceDisclaimer.title")}
          visible={visible}
        >
          <Modal.BodyText>
            <div style={{ marginTop: "13px", marginBottom: "17px" }}>
              {t("complianceTracking:complianceDisclaimer.action.message2")}
            </div>
          </Modal.BodyText>
        </Transactional>
      )}
    </>
  );
};
