import { AddLincenseBodyInterface, RegulatorsLicenseType } from "@cpe/models/regulators-license";
import * as FetchUtil from "../../fetch-util";

export type ReportingDateRequest = {
  regulatorLicenseVersion: string;
  issueDate: string;
  expirationDate: string;
  birthMonth?: string;
};
export type ReportingDateBulkRequest = { [Key: string]: ReportingDateRequest };
export class RegulatorsLicenseService {
  static get(
    userId?: string,
    excludeLicensesForBulkCreation = false
  ): Promise<FetchUtil.HttpResponse<RegulatorsLicenseType>> {
    if (excludeLicensesForBulkCreation) {
      return FetchUtil.get(`${regulatorsLicenseApiMatchers.regulators}?excludeLicensesForBulkCreation=true`);
    }
    if (userId) {
      return FetchUtil.get(`${regulatorsLicenseApiMatchers.regulators}?excludeLicensesOfUserId=${userId}`);
    }
    return FetchUtil.get(regulatorsLicenseApiMatchers.regulators);
  }
  static getReportDate(params: ReportingDateRequest): Promise<FetchUtil.HttpResponse<string>> {
    const { regulatorLicenseVersion, issueDate, expirationDate, birthMonth } = params;
    const completeUrl = `${
      regulatorsLicenseApiMatchers.reportDate
    }?regulatorLicenseVersion=${regulatorLicenseVersion}&issueDate=${issueDate}&expirationDate=${expirationDate}${
      birthMonth ? `&birthMonth=${birthMonth}` : ""
    }`;
    return FetchUtil.get(completeUrl);
  }
  static getReportDateBulk(
    params: ReportingDateBulkRequest
  ): Promise<FetchUtil.HttpResponse<{ [Key: string]: string }>> {
    return FetchUtil.post(regulatorsLicenseApiMatchers.reportDateBulk, params);
  }
  static postLicense(addLincense: AddLincenseBodyInterface, userId?: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(`${regulatorsLicenseApiMatchers.addLicense}${userId ? `?userId=${userId}` : ""}`, {
      ...addLincense
    });
  }
}

export const regulatorsLicenseApiMatchers = {
  regulators: `/api/v1/compliance/regulators`,
  reportDate: "/api/v1/compliance/regulators/reportDate",
  reportDateBulk: "/api/v1/compliance/licenses/bulk/regulators/reportDate",
  addLicense: `/api/v1/compliance/licenses`
};
