import { Icon, Typography } from "@beckersdp/becker-ds";
import { NavigationItem } from "@cpe/models/navigation-link";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useComplianceSectionContext } from "../../../../../../../../pages/home/components/compliance-section/compliance-section-provider";
import { availableRoutes } from "../../../../../../../../router/routes";
import useComplianceDisclaimerContext from "../../../../../../../compliance-legal-disclaimer/compliance-disclaimer-provider";

type Props = {
  link: NavigationItem;
};

export const MobileNavLink: FC<Props> = props => {
  const { link } = props;
  const { shouldShowComplianceDisclaimer, openComplianceDisclaimer } = useComplianceDisclaimerContext();
  const complianceCredits = useComplianceSectionContext();
  const { pathname } = useLocation();
  const end = false;
  const endSlashPosition = link.url !== "/" && link.url.endsWith("/") ? link.url.length - 1 : link.url.length;
  const isActive =
    pathname === link.url || (!end && pathname.startsWith(link.url) && pathname.charAt(endSlashPosition) === "/");
  const isComplianceLink = link.url === availableRoutes.compliance;
  const hasLicenses = complianceCredits?.complianceCredits && complianceCredits?.complianceCredits?.length > 0;
  return (
    <A
      href={link.url}
      $isActive={isActive}
      onClick={event => {
        if (isComplianceLink && hasLicenses && shouldShowComplianceDisclaimer()) {
          event.preventDefault();
          openComplianceDisclaimer();
          return;
        }
      }}
    >
      {link.iconKey && <Icon name={link.iconKey} />}
      <Text level="1" weight="bold">
        {link.label}
      </Text>
    </A>
  );
};

const Text = styled(Typography.Text)`
  color: ${props => props.theme.colors?.brand01[700]};
`;

export const A = styled.a<{ $isActive: boolean }>`
  box-sizing: border-box;
  height: 52px;
  border-top: solid 2px ${props => props.theme.colors.support01[300]};
  padding-left: 20px;
  display: inline-flex;
  gap: 20px;
  align-items: center;

  color: ${props => (props.$isActive ? props.theme.colors?.brand01.default : props.theme.colors?.brand01[700])};
  &.selected {
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:link,
  :visited {
    text-decoration: inherit;
    color: ${props => props.theme.colors?.brand01.default};
  }
  &:hover {
    text-decoration: none;
  }
  ${Text} {
    color: ${props => (props.$isActive ? props.theme.colors?.brand01.default : props.theme.colors?.brand01[700])};
  }
  ${Icon} {
    color: ${props => (props.$isActive ? props.theme.colors?.brand01.default : props.theme.colors?.brand01[700])};
  }
`;
