import { getLinkProps } from "./get-link-props";

const END_USER_LINKS = {
  facebook: "https://www.facebook.com/BeckerACCT/",
  instagram: "https://www.instagram.com/cpabecker/",
  linkedin: "https://www.linkedin.com/showcase/cpa-exam-review/",
  twitter: "https://twitter.com/BeckerCPE",
  youtube: "https://www.youtube.com/user/BeckerProfessionalED"
};

export const footerLinks /* :FooterProps*/ = (copyrightMsg: string) => ({
  links: [
    {
      href: "/help/contact",
      text: "Contact us",
      "data-qa-label": "contact-us",
      target: "_blank"
    },
    {
      href: "/help/faqs",
      text: "FAQ",
      "data-qa-label": "faq",
      target: "_blank"
    },
    {
      href: "https://www.becker.com/homepage/company-information/privacy-policy.html",
      text: "Privacy Policy",
      "data-qa-label": "privacy-policy",
      target: "_blank"
    }
  ],
  copyrightMsg,
  socialLinks: [
    { type: "instagram", target: "_blank", ...getLinkProps(END_USER_LINKS.instagram) },
    { type: "youtube", target: "_blank", ...getLinkProps(END_USER_LINKS.youtube) },
    { type: "twitter", target: "_blank", ...getLinkProps(END_USER_LINKS.twitter) },
    { type: "facebook", target: "_blank", ...getLinkProps(END_USER_LINKS.facebook) },
    { type: "linkedin", target: "_blank", ...getLinkProps(END_USER_LINKS.linkedin) }
  ]
});
