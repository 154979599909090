import { NavigationElement, NavigationLink } from "@cpe/models/navigation-link";
import { createSlice } from "@reduxjs/toolkit";
import { NavigationThunk, NavigationThunkV2 } from "./thunks";

interface UserState {
  navigation: NavigationLink[];
  help: NavigationLink[];
  loading: boolean;
  error: string | null;
  main: NavigationElement;
  helpLinks: NavigationElement;
}
export const initialState: UserState = {
  navigation: [],
  help: [],
  loading: false,
  error: null,
  main: [],
  helpLinks: []
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(NavigationThunk.fulfilled, (state, action) => {
      const response = action.payload;
      if (!response) {
        state.loading = false;
        state.navigation = [];
        state.error = "user not authenticated";
        return;
      }
      state.navigation = response.navigationLinks || [];
      state.help = response.helpLinks || [];
      state.loading = false;
      state.error = "";
    });
    builder.addCase(NavigationThunk.rejected, (state, action) => {
      state.loading = false;
      state.navigation = [];
      state.error = "user not authenticated";
    });
    builder.addCase(NavigationThunk.pending, (state, action) => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(NavigationThunkV2.fulfilled, (state, action) => {
      const response = action.payload;

      if (!response) {
        state.loading = false;
        state.main = [];
        state.error = "user not authenticated";
        return;
      }
      state.main = response.main || [];
      state.helpLinks = response.help || [];
      state.loading = false;
      state.error = "";
    });
    builder.addCase(NavigationThunkV2.rejected, (state, action) => {
      state.loading = false;
      state.main = [];
      state.error = "user not authenticated";
    });
    builder.addCase(NavigationThunkV2.pending, (state, action) => {
      state.error = "";
      state.loading = true;
    });
  }
});

export default navigationSlice.reducer;
