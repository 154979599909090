import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  onClick: () => void;
  open: boolean;
};

export const HamburgerButton: FC<Props> = props => {
  const { onClick, open } = props;
  const handleClick = () => {
    onClick();
  };
  return (
    <Container>
      <Link className={open ? "active-menu" : ""} onClick={handleClick}>
        <i>Menu</i>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Link = styled.button`
  all: unset;
  display: block;

  width: 20px;
  height: 16px;
  position: absolute;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    height: 0;
    height: 2px;
    background-color: ${props => props.theme.colors.brand01[600]};
    border-radius: 2px;
    width: 100%;
    left: 0;
    right: 0;
    transition: all ease-out 0.3s;
  }

  &:after {
    top: calc(100% - 2px);
  }

  i {
    display: block;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    height: 2px;
    border-radius: 1px;
    background-color: ${props => props.theme.colors.brand01[600]};
    width: 100%;
    position: absolute;
    top: 50%;
    transition: all ease-out 0.1s;
  }

  &.active-menu {
    &:after {
      transform: rotate(-45deg);
      transform-origin: center;
      top: 50%;
    }

    &:before {
      transform: rotate(45deg);
      transform-origin: center;
      top: 50%;
    }

    i {
      opacity: 0;
    }
  }
`;
