import { NavigationLink } from "@cpe/models/navigation-link";
import { useNavigation } from "../../../../hooks";
import { useAppDispatch } from "../../../../hooks/store";
import { useTranslation } from "../../../../i18n";
import { clearUser } from "../../../../store/user";
import { useFeatureFlags } from "../../../feature-flag/use-feature-flags";

export const useHeaderUrls = () => {
  const { t } = useTranslation(["context_app"]);
  const dispatch = useAppDispatch();
  const { help } = useNavigation();
  const { isFFActive } = useFeatureFlags();
  const guides: NavigationLink[] = !isFFActive("ADMIN_GUIDES")
    ? [
        {
          description: t("context_app:user_guides"),
          url: "/help/guides",
          external: false,
          permission: "USERGUIDES"
        }
      ]
    : help || [];
  const urls: { help: NavigationLink[]; account: FixedNavigationLink[] } = {
    help: [...guides],
    account: [
      {
        name: t("context_app:settings"),
        action: () => (window.location.href = "/account/profile")
      },
      {
        name: t("context_app:logout"),
        action: () => {
          window.sessionStorage.clear();
          dispatch(clearUser());
          window.location.href = "/logout";
        }
      }
    ]
  };
  return urls;
};

type FixedNavigationLink = {
  name: string;
  action: () => void;
};
