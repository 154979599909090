import { DefaultGAEventProps } from "../../ga";

export const GA_COMPLIANCE_ADD_LICENSE: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "ADD_LICENSE"
};

export const GA_COMPLIANCE_NAVIGATION: DefaultGAEventProps = {
  eventCategory: "CT-HEADER-NAVIGATION",
  eventAction: "CLICK_COMPLIANCE"
};

export const GA_COMPLIANCE_PRINT_ALL_REPORT: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "REPORT_PRINT_ALL"
};

export const GA_COMPLIANCE_EXPORT_ALL_REPORT: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "REPORT_EXPORT_ALL"
};

export const GA_COMPLIANCE_DOWNLOAD_ALL_CERTIFICATES: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "DONWLOAD_ALL_CERTIFICATES"
};

export const GA_COMPLIANCE_DELETE_CERTIFICATE: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "DELETE_CERTIFICATE"
};

export const GA_COMPLIANCE_EDIT_CERTIFICATE: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "EDIT_CERTIFICATE"
};

export const GA_COMPLIANCE_VIEW_CERTIFICATE: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "VIEW_CERTIFICATE"
};

export const GA_BREAKDOWN_ONBOARDING_START_TRACKING: DefaultGAEventProps = {
  eventCategory: "CT-BREAKDOWN-ONBOARD",
  eventAction: "REPORT_EXPORT_ALL"
};
export const GA_BREAKDOWN_ONBOARDING_LEARN_MORE: DefaultGAEventProps = {
  eventCategory: "CT-COMPLIANCE-BREAKDOWN",
  eventAction: "START_TRACKING"
};
export const GA_BREAKDOWN_ONBOARDING_PRINT: DefaultGAEventProps = {
  eventCategory: "CT-BREAKDOWN-ONBOARD",
  eventAction: "PRINT"
};

export const GA_BREAKDOWN_AICPA_ISSUES_BANNER_PRINT: DefaultGAEventProps = {
  eventCategory: "CT-BREAKDOWN-ONBOARD",
  eventAction: "PRINT"
};

export const GA_COMPLIANCE_WIDGET_TRIAL_START = (value: number): DefaultGAEventProps => {
  return {
    eventCategory: "CT-COMPLIANCE-TRIAL",
    eventAction: "START",
    eventValue: value
  };
};

export const GA_COMPLIANCE_WIDGET_TRIAL_TELL_MORE = (value: number, trialStatus: string): DefaultGAEventProps => {
  return {
    eventCategory: "CT-COMPLIANCE-TRIAL",
    eventAction: "TELL_MORE",
    eventValue: value
  };
};
