import { Button } from "@beckersdp/becker-ds";
import { ButtonProps } from "@beckersdp/becker-ds/dist/button/types";
import React, { FC } from "react";
import { useCourseCardContext } from "../../src/components/course-card/course-card-provider";
import { useCart } from "../../src/hooks";
import { useTranslation } from "../../src/i18n";
type Props = {
  size: ButtonProps["size"];
};
export const SubscriptionButton: FC<Props> = props => {
  const { addToCart, addingToCart, course } = useCourseCardContext();
  const cart = useCart();
  const { t } = useTranslation(["cart"]);
  const isInCart = Boolean(cart?.items?.find(item => item.productId === course.productId));
  return (
    <Button size={props.size} loading={addingToCart} onClick={addToCart} disabled={isInCart} type="onboarding">
      {isInCart ? t("cart:added_to_cart") : t("cart:add_to_cart")}
    </Button>
  );
};
