import { NavigationLink } from "@cpe/models/navigation-link";

export const useNavigationLinkGroup = (navigationLinks: NavigationLink[]) => {
  if (navigationLinks.length === 0) {
    return { groupedNavLinks: [] };
  }
  const groupedLinks = navigationLinks.reduce(function (r: any, a) {
    r[a.groupLabel || "NoGroup"] = r[a.groupLabel || "NoGroup"] || [];
    r[a.groupLabel || "NoGroup"].push(a);
    return r;
  }, Object.create(null));
  const linkGroups = Object.entries(groupedLinks).filter(([name, value]) => name !== "NoGroup");
  const groupedNavLinks = [...groupedLinks["NoGroup"]];
  linkGroups.forEach(([name, value]: [name: string, value: NavigationLink[]]) => {
    const index = navigationLinks.findIndex(link => link.groupLabel === name);
    groupedNavLinks.splice(
      index,
      0,
      value?.length > 1
        ? {
            url: "#",
            permisson: name,
            description: name,
            children: value,
            external: false
          }
        : value[0]
    );
  });
  return { groupedNavLinks };
};
