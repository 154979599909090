import { Icon, Typography } from "@beckersdp/becker-ds";
import React, { FC } from "react";
import styled from "styled-components";
import { useUser } from "../../hooks";
const size = ["small", "large"] as const;
type size = typeof size[number];

type Props = {
  size: size;
};

const StylesMap: {
  [K in size]: {
    width: string;
    height: string;
    level?: string;
    fontSize?: string;
  };
} = {
  small: {
    width: "36px",
    height: "36px",
    level: "2"
  },
  large: {
    width: "58px",
    height: "58px",
    fontSize: "22px"
  }
};
export const UserAvatar: FC<Props> = props => {
  const { size } = props;
  const { user } = useUser();
  const initials = `${user.firstName[0]?.toUpperCase() || ""}${user.lastName[0]?.toUpperCase() || ""}`;

  const shouldRenderInitials = initials.length === 2 && initials !== "..";
  return (
    <CustomAvatar $size={size}>
      {shouldRenderInitials ? (
        <Typography.Text level="2" weight="regular">
          {initials}
        </Typography.Text>
      ) : (
        <Icon name="user" />
      )}
    </CustomAvatar>
  );
};
const CustomAvatar = styled.div<{ $size: size }>`
  height: ${props => StylesMap[props.$size].width};
  width: ${props => StylesMap[props.$size].height};

  border-radius: 50%;
  background-color: ${props => props.theme.colors.brand01[100]};
  display: flex;
  align-items: center;
  justify-content: center;

  ${Typography.Text} {
    font-size: ${props => (StylesMap[props.$size].fontSize ? StylesMap[props.$size].fontSize : "inherit")};
  }
`;
