import { Typography } from "@beckersdp/becker-ds";
import { NavigationGroup, NavigationItem } from "@cpe/models/navigation-link";
import kebabCase from "lodash/kebabCase";
import React, { FC } from "react";
import styled from "styled-components";
import { SubMenuLink } from "./sub-menu-link";
type Props = {
  title: string;
  item: NavigationGroup;
};
export const SubMenuGroup: FC<Props> = props => {
  const { title, item } = props;
  return (
    <Container data-qa-label={`${kebabCase(item.label)}-group`} role="list">
      <LI>
        <Typography.Text level="3" weight="regular" style={{ textTransform: "uppercase" }}>
          {title}
        </Typography.Text>
      </LI>
      {item.items.map(item => (
        <SubMenuLink key={item.label} item={item as NavigationItem} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 190px;
`;
const LI = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding-bottom: 10px;
  padding-left: 8px;
  border-bottom: solid 2px ${props => props.theme.colors.support01[300]};

  flex-grow: 0;
  flex-shrink: 0;

  & :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
`;
