import React, { FC } from "react";
import { useDeviceBreakpoint } from "../../../../hooks/use-device-breakpoint";
import { ComplianceSectionProvider } from "../../../../pages/home/components/compliance-section/compliance-section-provider";
import { usePopupMessages } from "../../../../pages/home/components/popup-messages/use-popup-messages";
import { useFeatureFlags } from "../../../feature-flag/use-feature-flags";
import { Header as DeprcatedHeader } from "../header-deprecated";
import { DesktopHeader } from "./components/desktop-header";
import { MobileHeader } from "./components/mobile-header";
// eslint-disable-next-line
const BeckerLogo = require("../../../../assets/logo.svg");

export const Header: FC = () => {
  const { isFFActive, isLoading } = useFeatureFlags();
  const { isPhone, isTablet } = useDeviceBreakpoint();
  const { setRemoteDismiss, feebacksToShow, isLoadingFeedbacks } = usePopupMessages();

  if (isLoading === true || typeof isLoading === "undefined") {
    return null;
  }

  if (!isFFActive("REORG_ADMIN_NAVIGATION")) {
    return <DeprcatedHeader />;
  }

  return (
    <ComplianceSectionProvider
      alertsToShow={[]}
      setRemoteDismiss={setRemoteDismiss}
      feebacksToShow={feebacksToShow}
      isLoadingFeedbacks={isLoadingFeedbacks}
    >
      {isPhone || isTablet ? <MobileHeader /> : <DesktopHeader />}
    </ComplianceSectionProvider>
  );
};
