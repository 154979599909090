import { Alert } from "@cpe/models/alert";
import { Feedback, FeedbackType } from "@cpe/models/feedback";
import React, { FC, createContext, useContext } from "react";
import { ComplianceCreditsState, useComplianceCredits } from "./use-compliance-credits";

export interface ComplianceCredits extends ComplianceCreditsState {
  alertsToShow: Alert[];
  feebacksToShow: Feedback[];
  setRemoteDismiss: (feedbackType: FeedbackType) => Promise<void>;
  isLoadingFeedbacks: boolean;
}

type Props = {
  alertsToShow: Alert[];
  feebacksToShow: Feedback[];
  setRemoteDismiss: (feedbackType: FeedbackType) => Promise<void>;
  isLoadingFeedbacks: boolean;
};

const ComplianceSectionContext = createContext<ComplianceCredits | undefined>(undefined);

export const ComplianceSectionProvider: FC<Props & { children: React.ReactNode }> = props => {
  const { alertsToShow, setRemoteDismiss, feebacksToShow, isLoadingFeedbacks, ...rest } = props;
  const complianceCredits = useComplianceCredits();

  return (
    <ComplianceSectionContext.Provider
      value={{ ...complianceCredits, alertsToShow, setRemoteDismiss, feebacksToShow, isLoadingFeedbacks }}
      {...rest}
    />
  );
};

/**
 * Uses the context provided by the `ComplianceSectionContext`.
 *
 * @returns {ComplianceSectionContextValue} - The provided context values.
 */
export const useComplianceSectionContext = (): ComplianceCredits => {
  const context = useContext(ComplianceSectionContext);

  if (context === undefined) {
    throw new Error("useComplianceSectionContext must be used within a ComplianceSectionProvider");
  }

  return context;
};

export default ComplianceSectionContext;
