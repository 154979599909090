/* eslint-disable @typescript-eslint/no-empty-function */
import "core-js/stable";
import "element-scroll-polyfill";
import "./i18n";
import "./webpack_define_public_path";
(function ricShim() {
  (window as any).requestIdleCallback =
    (window as any).requestIdleCallback ||
    ((cb: any) =>
      setTimeout(() => {
        const start = Date.now();
        cb({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }, 1));
  (window as any).cancelIdleCallback = (window as any).cancelIdleCallback || ((id: any) => window.clearTimeout(id));
})();
