import { Course } from "@cpe/models/course";
import { Section, SectionRequest } from "@cpe/models/section";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SectionCourses, Sections } from "./thunks";

export interface SectionsState {
  sections: Section[];
  courses: { [key: string]: Course };
  loading: boolean | undefined;
  error: string | null;
  savedItemsIds: string[];
  isLoadingRecommendations: boolean;
}

export const initialState: SectionsState = {
  sections: [],
  courses: {},
  loading: undefined,
  isLoadingRecommendations: false,
  error: null,
  savedItemsIds: []
};

/**
 *
 * Build SectionsState
 *
 * @param {Object} - App state
 * @param {Array} - Response from Sections request
 *
 * @returns {Array} - Builded array to match Section's interface
 */
const buildSections = (state: any, response: SectionRequest[]): Section[] => {
  const sectionsToSave: Section[] = [];

  response?.map((section, sectionIndex) => {
    sectionsToSave[sectionIndex] = { ...section, courses: [] };
    return section.courses.map(course => {
      if (section.id === "SAVED") {
        state.courses[course.productId] = course;
        state.savedItemsIds = [...state.savedItemsIds, course.productId];
      }
      sectionsToSave[sectionIndex].courses.push(course.productId);
    });
  });
  return sectionsToSave;
};

export const sectionsSlice = createSlice({
  name: "sections",
  initialState: initialState,
  reducers: {
    addSavedItem: (state, action: PayloadAction<string>) => {
      state.savedItemsIds = [action.payload, ...state.savedItemsIds];
      state.courses[action.payload].state.saved = true;
    },
    removeSavedItem: (state, action: PayloadAction<string>) => {
      state.savedItemsIds = [...state.savedItemsIds.filter(productId => productId !== action.payload)];
      state.courses[action.payload].state.saved = false;
    },
    removeSectionCourse: (state, action: PayloadAction<{ sectionIndex: number; productId: string }>) => {
      const courses = state.sections[action.payload.sectionIndex].courses;
      state.sections[action.payload.sectionIndex].courses = courses.filter(id => id !== action.payload.productId);
    }
  },
  extraReducers: builder => {
    builder.addCase(Sections.fulfilled, (state, action) => {
      const response = action.payload;
      if (response?.statusCode) {
        state.error = response?.message;
      } else {
        state.sections = buildSections(state, action.payload as SectionRequest[]);
        state.error = "";
      }

      state.loading = false;
    });
    builder.addCase(Sections.rejected, (state, action) => {
      state.loading = false;
      state.error = "error";
    });
    builder.addCase(Sections.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(SectionCourses.fulfilled, (state, action) => {
      const response = action.payload;
      if (response?.statusCode) {
        state.error = response?.message;
      } else {
        const coursesIds: string[] = [];
        ((response as Course[]) || [])?.map(course => {
          state.courses[course.productId] = course;
          coursesIds.push(course.productId);
        });
        state.sections[action.meta.arg.index].courses = coursesIds;
        state.error = "";
      }
      state.sections[action.meta.arg.index].isLoading = false;
      state.isLoadingRecommendations = state.sections.filter(section => section.isLoading).length > 0;
    });
    builder.addCase(SectionCourses.pending, (state, action) => {
      state.isLoadingRecommendations = true;
      state.sections[action.meta.arg.index].isLoading = true;
      state.error = null;
    });
    builder.addCase(SectionCourses.rejected, (state, action) => {
      state.loading = false;
      state.error = "error";
    });
  }
});

export const { addSavedItem, removeSavedItem, removeSectionCourse } = sectionsSlice.actions;
export default sectionsSlice.reducer;
