export interface NavigationLink {
  url: string;
  description: string;
  permission: Permission;
  external: boolean;
  groupLabel?: string;
  children?: NavigationLink[];
}

export const permissions = {
  home: "HOME",
  myCourses: "MY_COURSES",
  calendar: "CALENDAR",
  accomplishments: "ACCOMPLISHMENTS",
  b2b: "B2B",
  coursesBuilder: "COURSE_BUILDER",
  gradebook: "GRADEBOOK",
  users: "USERS",
  reports: "REPORTS",
  complianceTracker: "COMPLIANCE_TRACKER",
  administration: "ADMINISTRATION",
  learningPaths: "LEARNING_PATHS"
};

type Permission =
  | "HOME"
  | "MY_COURSES"
  | "CALENDAR"
  | "ACCOMPLISHMENTS"
  | "B2B"
  | "COURSE_BUILDER"
  | "GRADEBOOK"
  | "USERS"
  | "REPORTS"
  | "COMPLIANCE_TRACKER"
  | "ADMINISTRATION"
  | "USERGUIDES" // TODO: Remove when removing isFFActive("ADMIN_GUIDES")
  | "LEARNER_GUIDE"
  | "ADMIN_GUIDE"
  | "LEARNING_PATHS";

export type NavigationLinkResponse = {
  main: NavigationElement;
  help: NavigationElement;
};

export type NavigationElement = Array<NavigationItem | NavigationGroup>;
export type NavigationItem = {
  url: string;
  external?: boolean;
  permissions: Permission[];
  iconKey?: string;
  label: string;
};

export type NavigationGroup = {
  label: string;
  items: NavigationElement;
};
