import { createSelector } from "@reduxjs/toolkit";
import compact from "lodash/compact";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import moment from "moment-timezone";
import { LocalStorageType, SessionStorageType } from "../constants/storage";
import { useAppSelector } from "../store";
import { WindowStorageType, setStorageItem, storageKeys } from "../store/storage";
import { useAppDispatch } from "./store";

const selectSavedItemsIds = (state: any) => state.sections.savedItemsIds;
const selectCourses = (state: any) => state.sections.courses;

const selectSectionCourses = (coursesIds: string[]) =>
  createSelector(selectCourses, courses => compact(coursesIds.map(id => courses[id])));

const selectSavedItems = createSelector(selectSavedItemsIds, selectCourses, (savedItemsIds, courses) =>
  compact(savedItemsIds.map((productId: string) => courses[productId]))
);

export const useNavigation = () => useAppSelector(state => state.navigation);
export const useUser = () => useAppSelector(state => state.user);
export const useSections = () => useAppSelector(state => state.sections);
export const useCart = () => useAppSelector(state => state.cart);
export const useCourses = () => useAppSelector(state => state.courses);
export const useRegulators = () => useAppSelector(state => state.regulators);
export const useToasts = () => useAppSelector(state => state.toasts);
export const useComplianceUsers = () => useAppSelector(state => state.complianceUsers);
export const useActiveFeatureFlags = () => useAppSelector(state => state.activeFeatureFlags);
export const useSectionCourses = (coursesIds: string[]) =>
  useAppSelector(state => selectSectionCourses(coursesIds)(state));
export const useSavedItems = () => useAppSelector(state => selectSavedItems(state));
export const useEula = () => useAppSelector(state => state.eula);
export const useFeedback = () => useAppSelector(state => state.feedback);

export const useStorage = () => {
  const storage = useAppSelector(state => state.storage);
  const dispatch = useAppDispatch();
  const { user } = useUser();

  return {
    ...storage,
    getStorageItem: (key: LocalStorageType | SessionStorageType, type: WindowStorageType): any | undefined => {
      const items =
        type === storageKeys.localStorage
          ? storage.localStorage?.[key as LocalStorageType]
          : storage.sessionStorage?.[key as SessionStorageType];

      let item: any;
      if (!isEmpty(items)) {
        if (typeof items === "string") {
          const isObject = items?.startsWith("{") && items?.endsWith("}");
          item = isObject ? JSON.parse(items) : items;
        } else {
          item = items;
        }
      }

      return item;
    },
    getStorageObjectItemProperty: (
      key: LocalStorageType | SessionStorageType,
      type: WindowStorageType,
      property?: string
    ): any | undefined | null => {
      const removePropertyFromStorage = (item: any, property: string) => {
        const cloneItem = omit(item, [property]);
        dispatch(
          setStorageItem({
            key: key,
            type: type,
            value: cloneItem
          })
        );
      };

      const items =
        type === storageKeys.localStorage
          ? storage.localStorage?.[key as LocalStorageType]
          : storage.sessionStorage?.[key as SessionStorageType];

      let item: any;
      if (!isEmpty(items)) {
        if (typeof items === "string") {
          const isObject = items?.startsWith("{") && items?.endsWith("}");
          item = isObject ? JSON.parse(items) : items;
        } else {
          item = items;
        }
      }

      if (!property) {
        return item;
      }

      const itemProperty = get(item, property, undefined);

      if (itemProperty?.expirationDate && moment().tz(user.timezone).diff(itemProperty?.expirationDate, "days") > 0) {
        removePropertyFromStorage(item, property);
        return;
      }

      return item ? itemProperty : undefined;
    }
  };
};
